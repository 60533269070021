<template>
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0 bg1 position-relative">
      <div class="row m-0 p-0">
        <!-- ------------------------------------------ -->
        <div class="col-11 m-0 p-0 bg2">
          <!-- -------------------------------------------------- -->
          <div class="row m-0 pl-5 pt-3">
            <!-- <div class="mx-0 mt-2 p-0">
              <img class="mr-5" src="/images/Vector.png" alt="" />
            </div>
            <div class="m-0 p-0">
              <WaitActive :text="status" disable="N" />
            </div> -->
          </div>
          <!-- -------------- 2--------------------------- -->
          <div class="row mx-0 mb-5 p-0">
            <div class="col-4 mx-0 mt-3 p-0 d-flex justify-content-center">
              <img class="img" :src="img" alt="" />
            </div>
            <div class="col m-0 p-0">
              <div class="col-12 mx-0 mt-2 p-0 d-flex justify-contnet-center">
                <p class="m-0 p-0 id">Job ID : {{ id }}</p>
              </div>
              <div class="col-12 mx-0 mt-2 p-0 d-flex justify-contnet-center">
                <p class="m-0 p-0 tital">{{ tital }}</p>
              </div>
              <div class="col-12 mx-0 mt-2 p-0 d-flex justify-contnet-center">
                <p class="m-0 p-0 date">{{ date }}</p>
              </div>
              <!-- <div class="col-12 mx-0 mt-4 p-0">
                <ButtonDetail text="DETAILS" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class=" p-0 positionimg">
      <img class=" " src="/images/arrow.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import WaitActive from "@/components/Common/WaitActive.vue";
export default {
  name: "RightBox",
  props: ["status", "img", "due", "id", "tital", "date", "detail"],
  components: { WaitActive },
  
};
</script>

<style scoped>
.bg1 {
  background: #64748b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
}
.bg2 {
  background: #e2e8f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
}
.id {
  color: #475569;
  font-weight: 700;
  font-size: 2.25em;
}
.tital {
  font-weight: 600;
  font-size: 1.5em;
  color: #0f172a;
}
.date {
  font-weight: 400;
  font-size: 0.75em;
  color: #64748b;
}
.img {
  width: 158.23px;
  height: 129px;
}
.positionimg {
  position: absolute;
  right: 15px;
  top: 115px;
 
}
</style>