<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="card m-0 p-0">
      <!-- -------------------------- Header--------------------- -->
      <div class="row m-0 p-0 w-100">
        <!-- ----------------------- -->
        <div class="col-12 col-md-12 col-lg-4 m-0 p-0">
          <LeftHeader
            tital="JOB"
            subtital="nonononoono nooononononon"
            line="1"
          />
        </div>
        <!-- ----------------------- -->
        <div class="col-12 col-md-12 col-lg-8 m-0 p-4">
          <RightHeaderSearch />
        </div>
        <!-- ----------------------- -->
      </div>
      <!-- --------------------------------body--------------------------- -->
      <div class="row my-5 mx-sm-1 mx-md-1 mx-lg-5 p-0">
        <div class="col-12 m-0 p-0 col-md-6">
          <div
            class="col-12 mx-0 mb-3 p-0"
            v-for="(e, index) in id1"
            :key="index"
          >
            <LeftBox
              status="Active"
              img="/images/home.png"
              :due="e.due"
              :id="e.id"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div>
          <!-- <div class="col-12 mx-0 my-4 p-0">
            <LeftBox
              status="Active"
              img="/images/home.png"
              due="LATE"
              id="0001"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div>
          <div class="col-12 mx-0 my-4 p-0">
            <LeftBox
              status="Active"
              img="/images/home.png"
              due="URGENT"
              id="0001"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div> -->
        </div>
        <div class="col-12 m-0 px-4 col-md-6">
          <div
            class="col-12 mx-0 mb-3 p-0"
            v-for="(e, index) in id"
            :key="index"
          >
            <RightBox
              @click="gotojob(e.id)"
              status="Active"
              img="/images/home.png"
              due="URGENT"
              :id="e.id"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div>
          <!-- <div class="col-12 mx-0 mb-3 p-0">
            <RightBox
              status="Active"
              img="/images/home.png"
              due="URGENT"
              id="0001"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div>
          <div class="col-12 mx-0 mb-3 p-0">
            <RightBox
              status="Active"
              img="/images/home.png"
              due="URGENT"
              id="0001"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div>
          <div class="col-12 mx-0 mb-3 p-0">
            <RightBox
              status="Active"
              img="/images/home.png"
              due="URGENT"
              id="0001"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div>
          <div class="col-12 mx-0 mb-3 p-0">
            <RightBox
              status="Active"
              img="/images/home.png"
              due="URGENT"
              id="0001"
              tital="เซ็นสัญญา แจ้งยอดชำระ"
              date="ล่าสุด 03-07-2564 เวลา 13.00น."
              detail=""
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftHeader from "@/components/Common/LeftHeader.vue";
import RightHeaderSearch from "@/components/Common/RightHeaderSearch.vue";
import LeftBox from "@/components/Job/LeftBox.vue";
import RightBox from "@/components/Job/RightBox.vue";
export default {
  name: "JobPage",
  components: { LeftHeader, RightHeaderSearch, LeftBox, RightBox },
  data() {
    return {
      id: [
        { id: "0001" },
        { id: "0002" },
        { id: "0003" },
        { id: "0004" },
        { id: "0005" },
      ],
      id1: [
        { id: "0001", due: "LATE" },
        { id: "0002", due: "LATE" },
        { id: "0003", due: "URGENT" },
      ],
    };
  },
  methods: {
    gotojob(id) {
      //console.log(id);
      let convertid = this.$MAIN.Base64.encode(id)
      this.$router.push({ name: "JobDetailPage", params: { id: convertid } });
    },
  },
};
</script>

<style>
</style>